<script>
import CustomPageCommon from '@/components/CustomPageCommon'

export default {
  extends: CustomPageCommon,
  async asyncData({ params, $axios, redirect, $bugsnag, store }) {
    const slug = params.pathMatch
      .split('/')
      .slice(1)
      .join('_-_-_')
    const url = `/ca/${store.state.settings.school}/catalogPages/${
      store.state.settings.activeCatalog
    }/${slug}`
    try {
      const page = await $axios.$get(url, {
        params: {
          slug: true,
          published: true,
        },
      })
      return {
        page,
      }
    } catch (e) {
      $bugsnag.notify(e)
      redirect('/404')
    }
  },
}
</script>
